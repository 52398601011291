



















































































import { CONST_RESERVATION_DELETE_REASON } from '@/common';
import BaseComponent from '@/core/base.component';
import DeleteReservationRequestDto from '@/dto/reservation/delete-reservation-request.dto';
import GetReservationResponseDto from '@/dto/reservation/get-reservation-response.dto';
import reservationService from '@/services/reservation.service';

import { Component, Vue } from 'vue-property-decorator';
@Component({
  name: 'Check',
})
export default class Check extends BaseComponent {
  private reservationInfo = new GetReservationResponseDto();
  private deleteReservationRequestDto = new DeleteReservationRequestDto();
  private formattedDate = '';
  private selectedCancelReason = '';
  private othersText = '';
  private cancelReasons = CONST_RESERVATION_DELETE_REASON;
  onReturnBtn() {
    this.$router.push('/reservation');
  }
  onCancelOk() {
    this.deleteReservationRequestDto.id = +this.$route.params.id;
    this.deleteReservationRequestDto.reservationCode = this.reservationInfo.reservationCode;
    this.deleteReservationRequestDto.phone = this.reservationInfo.phone;
    if (this.selectedCancelReason === '기타') {
      this.deleteReservationRequestDto.deleteReason = this.selectedCancelReason;
      this.deleteReservationRequestDto.deleteReasonEtc = this.othersText;
    } else {
      this.deleteReservationRequestDto.deleteReason = this.selectedCancelReason;
    }
    reservationService
      .cancelReservation(this.deleteReservationRequestDto)
      .subscribe(res => {
        this.$root.$bvToast.toast('예약 취소가 완료되었습니다', {
          title: '성공',
          variant: 'success',
          solid: true,
        });
        this.$router.push('/reservation');
      });
  }
  mounted() {
    reservationService
      .getReservInfo(sessionStorage.getItem('reservationCode'))
      .subscribe(res => {
        this.reservationInfo = res.data[res.data.length - 1];
        this.reservationInfo.reservationDate = new Date(
          this.reservationInfo.reservationDate,
        ).toLocaleString();
        this.formattedDate = this.reservationInfo.reservationDate.replace(
          /오.*$/,
          '',
        );
      });
  }
}
